<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GroupbuyingsList' }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>門市庫存品開團</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <section class="row mb-8">
        <div class="col-8 h5">設定開團資料</div>
        <div class="col-4 h5">
          <button class="btn btn-outline-danger" @click="handleDelete">
            刪除
          </button>
        </div>
        <div class="col-12 col-xl-8">
          <b-form-group>
            <b-form-select
              class="form-control form-control-sm col-12 col-xl-6"
              v-model="currentProductId"
              @change="handleChangeProduct"
            >
              <b-form-select-option
                v-if="productOptions && productOptions.length > 1"
                :value="null"
                disabled
              >
                請選擇商品名稱
              </b-form-select-option>
              <b-form-select-option
                v-for="productOption in productOptions"
                :key="productOption.id"
                :value="productOption.id"
              >
                {{ productOption.title }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-spinner
            style="width: 2rem; height: 2rem;"
            v-if="isFetchProductInfo"
            variant="secondary"
          ></b-spinner>
          <div class="mb-4 product-info" v-if="showProductInfo">
            <div>店內碼: {{ productInfo.barcode }}</div>
            <div>國際碼: {{ productInfo.ian }}</div>
            <div>原價: {{ productInfo.orig_price }}</div>
            <div>售價: {{ productInfo.sale_price }}</div>
          </div>

          <b-form-group
            label-cols="7"
            label-cols-lg="2"
            label-size="sm"
            label="庫存共"
            label-for="total_sku_quantity"
          >
            <b-form-input
              id="total_sku_quantity"
              class="mb-2 mr-sm-2 mb-sm-0"
              type="number"
              style="width: 80px"
              v-model="form.total_sku_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.total_sku_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>

            件
            <b-form-invalid-feedback :state="!v$.form.total_sku_quantity.lengthSix.$invalid">
                數字上限為六位數
            </b-form-invalid-feedback>

            <!-- <b-form-invalid-feedback
              :state="!v$.form.total_sku_quantity.less.$invalid"
            >
              msg for 庫存
            </b-form-invalid-feedback> -->
          </b-form-group>

          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            content-cols-xl="7"
            label-size="sm"
            label="開團群組"
            label-for="input-1"
          >
            <template v-if="true">
              <b-spinner
                small
                v-if="isFetchGroup"
                variant="secondary"
              ></b-spinner>

              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="form.group_ids"
              >
                <b-form-checkbox
                  class="mb-lg-2 mb-0"
                  v-for="group in groupOptions"
                  :key="group.id"
                  :value="group.id"
                  @change="handleGroupOptionChange(group)"
                >
                  <div class="row">
                    <div class="col-12 col-lg-6 mb-2 mb-xl-0">
                      {{ group.name }}
                    </div>
                    <div class="col-lg-6 text-center">
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="number"
                        style="width: 80px"
                        v-model="group.sku_quantity"
                        :disabled="!form.group_ids.includes(group.id)"
                        :formatter="
                          (value) => (value < 1 ? 0 : Math.round(value))
                        "
                        @click="handleSelect"
                        @change="handleGroupChange"
                        :state="(v$.form.sku_quantities.$error &&
                          form.group_ids.includes(group.id) &&
                          group.sku_quantity > 999999) ? false : null"
                      ></b-form-input>
                      件
                      <b-form-invalid-feedback v-if="form.group_ids.includes(group.id) && group.sku_quantity > 999999" :state="!v$.form.sku_quantities.lengthSix.$invalid">
                          數字上限為六位數
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-checkbox>
              </b-form-checkbox-group>

              <b-form-invalid-feedback :state="!v$.form.group_ids.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </template>
            <template v-else>
              <div v-if="this.selectedGroup">
                {{ this.selectedGroup.name }}
              </div>
            </template>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="每人最高購買數"
            label-for="once_max_quantity"
          >
            <b-form-input
              id="once_max_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_max_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_max_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_max_quantity.less.$invalid"
            >
              需小於商品總數上限
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_max_quantity.lengthSix.$invalid"
            >
              數字上限為六位數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="每人最低購買數"
            label-for="once_quantity"
          >
            <b-form-input
              id="once_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_quantity.less.$invalid"
            >
              需小於每人最高購買數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="下單數量遞增數"
            label-for="once_increase_quantity"
          >
            <b-form-input
              id="once_increase_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_increase_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_increase_quantity.$error ? false : null"
              @click="handleSelect"
              :disabled="!canEditOnceIncreaseQuantity"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_increase_quantity.less.$invalid"
            >
              下單遞增數與最低購買數加總不可大於最高購買數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="開始時間"
            label-for="input-1"
          >
            <div class="d-flex">
              <div class="w-50">
                <datepicker
                  class="mb-2"
                  placeholder="Select Date"
                  v-model="form.start_at.date"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabledDates="disabledDates"
                ></datepicker>
                <b-form-invalid-feedback
                  :state="!v$.form.start_at.date.required.$error"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>

              <div class="ml-2 w-50">
                <vue-timepicker
                  format="HH:mm"
                  v-model="form.start_at.time"
                  :input-class="['form-control']"
                ></vue-timepicker>
                <b-form-invalid-feedback
                  :state="
                    !v$.form.start_at.time.HH.$error ||
                    !v$.form.start_at.time.mm.$error
                  "
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="截止時間"
            label-for="input-1"
            description="*達截止時間系統將自動結團"
          >
            <div class="d-flex">
              <div class="w-50">
                <datepicker
                  class="mb-2"
                  placeholder="Select Date"
                  v-model="form.end_at.date"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :input-class="{
                    'is-invalid': v$.form.start_at.$invalid,
                  }"
                  :disabledDates="disabledDates"
                ></datepicker>
                <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                  此欄位為必填
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                >
                  截止時間必須大於開團時間
                </b-form-invalid-feedback>
              </div>

              <div class="ml-2 w-50">
                <vue-timepicker
                  :input-class="[
                    'form-control',
                    {
                      'is-invalid':
                        v$.form.start_at.time.HH.$error ||
                        v$.form.start_at.time.mm.$error ||
                        v$.form.start_at.date.beforeEndAt.$invalid,
                    },
                  ]"
                  v-model="form.end_at.time"
                ></vue-timepicker>
                <b-form-invalid-feedback
                  :state="
                    !v$.form.end_at.time.HH.$error ||
                    !v$.form.end_at.time.mm.$error
                  "
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="員工編號"
            label-for="tracking_code"
          >
            <b-form-input
              id="tracking_code"
              class="mb-2 mr-sm-2 mb-sm-0"
              style="width: 170px"
              v-model="form.tracking_code"
              :formatter="
                (value) => (value.length > 6 ? value.slice(0, 6) : value)
              "
              :state="v$.form.tracking_code.$error ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="v$.form.tracking_code.required.$invalid"
            >
              此欄位為必填
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="v$.form.tracking_code.lengthSix.$invalid"
            >
              需為6碼數字
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="順序"
            label-for="order"
          >
            <b-form-input
              id="order"
              class="mb-2 mr-sm-2 mb-sm-0"
              style="width: 170px"
              v-model="form.order"
              :state="v$.form.order.$error ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="v$.form.order.required.$invalid"
            >
              此欄位為必填
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="v$.form.order.lengthTwo.$invalid"
            >
              需為2碼數字
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </section>

      <div style="color:#e06666;padding:20px 0px;font-size:14px;">* 因 LINE 系統限制，請留意同期間開團數不得超過50個團購</div>

      <div class="d-flex justify-content-center buttons__action">
        <b-button
          class="mr-3"
          variant="success"
          @click="saveAndBackToUpdateImages"
        >
          設定<br class="d-lg-none" />商品圖片
        </b-button>

        <b-button class="mr-3" variant="success" @click="saveDraft">
          儲存草稿
        </b-button>

        <b-button variant="success" @click="save" :disabled="isSaving"> 確認開團 </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { format, isBefore, set, subDays } from "date-fns";
import { zh } from "vuejs-datepicker/dist/locale";
import branchApi from "@/apis/branch";
import groupApi from "@/apis/group";
import groupbuyApi from "@/apis/groupbuy";
import productApi from "@/apis/product";
import _ from "lodash";

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => {
    return {
      zh,
      branchId: null,
      currentProductId: null,
      product: null,
      productOptions: null,
      groupOptions: null,
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      isFetchFirstLayerBranches: false,
      isFetchSecondLayerBranches: false,
      isFetchThirdLayerBranches: false,
      isFetchGroup: false,
      canDivideTotal: false, // 用來決定是否可算分組組數,
      disabledDates: { to: subDays(new Date(), 1) },
      form: {
        branch_id: null,
        group_ids: [],
        all_group_ids: [],
        product_ids: null,
        total_sku_quantity: 1,
        sku_quantities: [],
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 購買遞增數
        // is_force_confirmed: false, // 強制結單
        max_quantity: 0, // 商品總數上限
        tracking_code: null,
        // group_type_ids: [],
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        // payday_period: 0,
        order: "50",
      },
      maxSkuQuantity: 0, //用做最高購買數不可大於最高群組配量的參考
      minSkuQuantity: 0, //用做最低購買數不可大於最低群組配量的參考
      canEditOnceIncreaseQuantity: false,
      isSaving: false,
      productInfo: {
        id: null,
        name: null,
        barcode: null, // 店內碼
        ian: null, // 國際碼
        rating: null, // 批價
        bargain_price: null, // 特價
        unit_price: null, // 單價
        discount: null, // 組合優惠
      },
      showProductInfo: false,
      isFetchProductInfo: false,
    };
  },
  validations() {
    return {
      form: {
        group_ids: { required },
        total_sku_quantity: {
          required,
          lengthSix: () => {
            return (
              Number(this.form.total_sku_quantity) <= 999999
            );
          },
        },
        sku_quantities: {
          lengthSix: (value) => {
            return value.filter((v) => { return v > 999999 }).length == 0
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
          lengthSix: () => {
            return (
              Number(this.form.once_max_quantity) <= 999999
            );
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: {
          required,
          less: () => {
            if (+this.form.once_max_quantity === +this.form.once_quantity) {
              return true;
            }
            return +this.form.once_increase_quantity + +this.form.once_quantity <= +this.form.once_max_quantity;
          },
        },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        tracking_code: {
          required,
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return (
              this.form.tracking_code.length === 6 &&
              /^[0-9]*$/.test(this.form.tracking_code)
            );
          },
        },
        order: {
          required,
          lengthTwo: () => {
            if (!this.form.order) return true;
            return (
              this.form.order.length <= 2 &&
              /^[0-9]*$/.test(this.form.order)
            );
          },
        },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    mapFirstLayerBranches() {
      return this.mapBranches(this.firstLayerBranches);
    },
    mapSecondLayerBranches() {
      return this.mapBranches(this.secondLayerBranches);
    },
    mapThirdLayerBranches() {
      return this.mapBranches(this.thirdLayerBranches);
    },
  },
  created: function () {
    this.currentProductId = this.$route.params.id;
    this.branchId = this.$route.params.branch_id;
    this.form.product_ids = [this.$route.params.id];
    this.getProductList();
    this.getGroups();
    this.getFirstLayerBranches();
  },
  watch: {
    "form.group_ids": function (newVal, oldVal) {
      if (!this.form.all_group_ids) {
        return;
      }

      // prevent infinite loop
      if (newVal.length == oldVal.length) {
        return;
      }

      // 確保form.group_ids 順序維持api 來的順序, 避免執行 divideTotalSku, 把餘數加錯團
      let result = [];
      for (let i = 0; i < this.form.all_group_ids.length; i++) {
        if (!newVal.includes(this.form.all_group_ids[i])) {
          continue;
        }

        result.push(this.form.all_group_ids[i]);
      }

      this.form.group_ids = result;
    },
    "form.total_sku_quantity": function (val) {
      if (!this.canDivideTotal) {
        return;
      }

      this.divideTotalSku(val);
    },
    currentProductId: function (val) {
      this.currentProductId = val;
      this.form.product_ids = [val];
      if (this.productOptions) {
        this.product = this.productOptions.find((p) => p.id == val);
        this.form.total_sku_quantity = this.product.skus[0].max_quantity ?? 1;
      }
      this.updateGroupSkuQuantity()
    },
    product: function (val) {
      this.updateProductInfo(this.product)
      if (val.groupbuyings.length >= 1) {
        this.$router.push({
          name: "StoreGroupbuyingDraft",
          query: {
            product_id: val.id,
          },
        }).catch(() => {});
      } else {
        this.$router
          .push({ name: "StoreGroupbuyingCreate", params: { id: val.id } })
          .catch(() => {});

        this.canDivideTotal = true;
      }
    },
    maxSkuQuantity: function () {
      this.form.once_max_quantity = this.maxSkuQuantity;
    },

    "form.once_max_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },

    "form.once_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },

    "form.order": function () {
      this.form.order = this.form.order.replace(/^0+(?=\d)/, "");

      // 確保輸入的值為數字，如果不是，則設置為空字串
      const parsedValue = parseFloat(this.form.order);
      if (isNaN(parsedValue)) {
        this.form.order = "";
      }
    }
  },
  methods: {
    /**
     * 最高購買數 != 最低購買數，數量遞增數需變成可編輯狀態
     * 最高購買數 == 最低購買數，數量遞增數需為停用，且設為1
     */
    handleCanEditOnceIncreaseQuantity () {
      if (parseInt(this.form.once_max_quantity) != parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = true;
      }

      if (parseInt(this.form.once_max_quantity) == parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = false;
        this.form.once_increase_quantity = 1;
      }
    },

    resetMaxMinSkuQuantity () {
      let me = this;

      this.maxSkuQuantity =
      _.chain(this.groupOptions)
      .filter(function (go) {
        if (me.form.group_ids.includes(go.id)) {
          return go;
        }
      })
      .maxBy(function (go) {
        return parseInt(go.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.maxSkuQuantity = parseInt(this.maxSkuQuantity);

      this.minSkuQuantity =
      _.chain(this.groupOptions)
      .filter(function (go) {
        if (me.form.group_ids.includes(go.id)) {
          return go;
        }
      })
      .minBy(function (go) {
        return parseInt(go.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.minSkuQuantity = parseInt(this.minSkuQuantity);
    },

    handleGroupChange() {
      let me = this;

      this.resetMaxMinSkuQuantity();

      let total = 0;
      for (let i = 0; i < this.groupOptions.length; i++) {
        if (!this.form.group_ids.includes(this.groupOptions[i].id)) {
          continue;
        }

        total += parseInt(this.groupOptions[i].sku_quantity);
      }

      this.canDivideTotal = false;
      this.form.total_sku_quantity = total;
      this.updateGroupSkuQuantity()

      this.$nextTick(function () {
        me.canDivideTotal = true;
      });
    },

    updateGroupSkuQuantity () {
      let me = this;
      const skuQuantities = this.form.group_ids.map(function (id) {
        const group = me.groupOptions.find((g) => g.id == id);
        return parseInt(group.sku_quantity);
      });
      this.form.sku_quantities = skuQuantities
    },

    handleGroupOptionChange() {
      this.divideTotalSku(this.form.total_sku_quantity);
    },

    handleSelect(e) {
      e.target.select();
    },

    divideTotalSku(total) {
      const groupCount = this.form.group_ids.length;
      if (total <= 0 || groupCount <= 0) {
        return;
      }

      const count = Math.floor(total / groupCount);
      const mod = total % groupCount;

      this.groupOptions.forEach((groupOpt) => {
        if (!this.form.group_ids.includes(groupOpt.id)) {
          groupOpt.sku_quantity = 0;
          return;
        }

        if (groupOpt.id == this.form.group_ids[0]) {
          groupOpt.sku_quantity = count + mod;
          return;
        }

        groupOpt.sku_quantity = count;
      });

      this.minSkuQuantity = count;
      this.maxSkuQuantity = count + mod;

      this.updateGroupSkuQuantity()

      this.$forceUpdate(); // 否則dom 不會更新
    },

    async getProductList() {
      try {
        const { data } = await productApi.getUngroupbuyingProducts({
          branch_id: this.branchId,
        });

        this.productOptions = data.data;
        this.product = this.productOptions.find(
          (p) => p.id == this.currentProductId
        );

        if (this.product) {
          this.form.total_sku_quantity = this.product.skus[0].max_quantity ?? 1;
        }
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "查無商品資料",
        });
      }
    },
    async save() {
      const result = await this.v$.$validate();
      console.log(this.v$.$errors);
      if (!result) {
        return;
      }

      const form = this.prepareForm();

      // 若有群組可賣數 =0，必須提示擋住不能送出
      if (form.sku_quantities.includes(0)) {
        this.$swal("失敗", "群組可賣量必須大於0", "error");
        return;
      }

      if (this.form.once_max_quantity > this.maxSkuQuantity) {
        this.$swal("失敗", "最高購買數不可大於最高群組配量", "error");
        return;
      }

      if (this.form.once_quantity > this.minSkuQuantity) {
        this.$swal("失敗", "最低購買數不可大於最低群組配量", "error");
        return;
      }

      // 開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需 > = 1
      if (! (parseInt(this.form.once_max_quantity) >= 1 && parseInt(this.form.once_quantity) >= 1 && parseInt(this.form.once_increase_quantity) >= 1)) {
        this.$swal("失敗", "開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需大於等於1", "error");
        return;
      }

      // 邏輯定義尚未明確, 先遮掉
      // if ((parseInt(this.form.once_increase_quantity) + parseInt(this.form.once_quantity)) > parseInt(this.form.once_max_quantity)) {
      //   this.$swal("失敗", "下單遞增數與最低購買數加總不可大於最高購買數", "error");
      //   return;
      // }



      if (form.sku_quantities.length <= 0) {
        this.$swal(
          "失敗",
          "請填寫庫存組數, 且庫存數必須等於所有團的組數加總",
          "error"
        );
        return;
      }

      if (this.product.images.length < 2) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "最少需上傳 2 張商品圖片",
        });
        return;
      }

      let ids;
      this.isSaving = true;

      try {
        const { data } = await groupbuyApi.createDraftGroupbuy(form);

        ids = data.data.map((groupbuying) => {
          if (groupbuying.status == '80') {
            return groupbuying.id;
          }
        });

      } catch (err) {
        let errMsg = err.response.data.message ?? "開團失敗";
        this.$swal("失敗", ` ${errMsg}`, "error");
        return;
      }

      try {
        const payload = {
          ids: _.compact(ids),
          branch_id: this.form.branch_id,
          once_max_quantity: this.form.once_max_quantity,
        };

        await groupbuyApi.startGroupbuy(payload);

        this.$swal.fire({
          icon: "success",
          text: "開團成功",
        });

        this.$router.push({
          name: "GroupbuyingsList",
          query: { tab: "going" },
        });
      } catch (err) {
        let errMsg = err.response.data.message ?? "開團失敗";
        this.$swal("失敗", ` ${errMsg}`, "error");
        this.$router.push({
          name: "StoreGroupbuyingDraft",
          query: {
            product_id: this.currentProductId,
          },
        });
      }
      this.isSaving = false;
    },

    prepareForm() {
      const me = this;

      const skuQuantities = this.form.group_ids.map(function (id) {
        const group = me.groupOptions.find((g) => g.id == id);
        return parseInt(group.sku_quantity);
      });

      const form = {
        ...this.form,
        sku_quantities: skuQuantities,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      return form;
    },

    /*
     * 編輯草稿＆設定開團資料兩頁的『設定商品圖片』的按鈕，要先作『儲存草稿』的功能
     * 若有錯誤，就不讓他去『設定商品圖片』並提示相關錯誤訊息
     */
    async saveAndBackToUpdateImages() {
      const form = this.prepareForm();

      try {
        await groupbuyApi.createDraftGroupbuy(form);

        this.$router.push({
          name: "StoreGroupbuyingUpdateImage",
        });
      } catch (err) {
        console.log(err);
        this.$swal("失敗", "新增草稿失敗", "error");
      }
    },

    async saveDraft() {
      const form = this.prepareForm();

      try {
        await groupbuyApi.createDraftGroupbuy(form);
        this.$swal.fire({
          icon: "success",
          text: "草稿儲存成功",
        });

        this.$router.push({
          name: "StoreGroupbuyingDraft",
          query: { product_id: this.currentProductId },
        });
      } catch (err) {
        console.log(err);
        this.$swal("失敗", "新增草稿失敗", "error");
      }
    },

    async getGroups() {
      try {
        this.isFetchGroup = true;
        const { data } = await groupApi.getGroups({
          status: 1,
          branch_id: this.branchId,
          sort_by: ["member_count,desc", "created_at,asc"],
        });

        if (data.data.length === 0) {
          this.$swal("失敗", "此分店無相關群組", "error");
          this.isFetchGroup = false;
          return;
        }

        this.groupOptions = data.data;
        this.form.group_ids = this.groupOptions.map((group) => group.id); // 預設把所有群組都打勾
        this.form.all_group_ids = this.groupOptions.map((group) => group.id);

        this.groupOptions.forEach((groupOpt) => {
          groupOpt.sku_quantity = 0; // 初始化平攤庫存為0
        });
      } catch (err) {
        console.log(err);
      }
      this.isFetchGroup = false;
    },
    async getFirstLayerBranches() {
      this.isFetchFirstLayerBranches = true;
      const branches = await this.getBelowBranches(this.currentBranch.id);
      this.firstLayerBranches = branches;
      if (branches.length > 1) {
        this.firstLayerBranches = branches.filter(
          (branch) => branch.id !== this.currentBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.firstBranch = this.mapFirstLayerBranches[0];
        this.form.branch_id = this.currentBranch.id;
      }

      this.isFetchFirstLayerBranches = false;
    },
    async handleFirstBranch(branch) {
      this.isFetchSecondLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      if (branches.length > 1) {
        this.secondLayerBranches = branches.filter(
          (branch) => branch.id !== this.firstBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.secondLayerBranches = [];
        this.form.branch_id = branch.id;
      }
      this.isFetchSecondLayerBranches = false;
    },
    async handleSecondBranch(branch) {
      this.isFetchThirdLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      this.thirdLayerBranches = branches;
      if (branches.length > 1) {
        this.thirdLayerBranches = branches.filter(
          (branch) => branch.id !== this.secondBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.form.branch_id = branch.id;
      }
      this.isFetchThirdLayerBranches = false;
    },
    async getBelowBranches(branch_id) {
      try {
        const { data } = await branchApi.getCurrentBelowBranches({
          branch_id,
        });

        return data.data.filter(
          (branch) => branch.org_id === this.organization.id
        );
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
        return;
      }
    },
    mapBranches(branches) {
      return branches
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order;
          }
        })
        .map((branch) => {
          let branchName = `${branch.branch_code} ${branch.name}`;
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`;
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`;
          }

          return {
            ...branch,
            value: branch.id,
            name: branchName,
          };
        });
    },
    async getProduct() {
      try {
        const { data } = await productApi.getProduct(this.currentProductId);
        this.product = data.data;
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "查無商品資料",
        });
      }
    },
    handleChangeProduct(event) {
      const selectedProduct = this.productOptions.find(
        (opt) => opt.id == event
      );
      if (selectedProduct.groupbuyings.length >= 1) {
        this.$router.push({
          name: "StoreGroupbuyingDraft",
          query: {
            product_id: event,
          },
        });
      }

      this.groupOptions = null;
      this.getGroups();
    },

    async handleDelete() {
      if (!this.currentProductId) {
        this.$swal("失敗", "請先選擇商品", "error");
        return;
      }

      this.$swal({
        title: "<strong>你確定要刪除嗎?</strong>",
        type: "warning",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>商品名稱：${this.product.title}</div>
            </div>
            <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
          </div>
        `,
        confirmButtonText: "確定刪除",
        cancelButtonText: "不刪除",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (!result.value) {
          return;
        }

        try {
          await groupbuyApi.deleteGroupbuyingsByProductForce(
            this.currentProductId
          );
          this.$swal.fire({
            icon: "success",
            text: "刪除商品成功",
          }).then(() => {
            window.location.reload();
          });
        } catch (err) {
          const message = err.response.data.message
          if (message.includes("No query results for model")) {
            this.$swal("失敗", '查無此商品', "error").then(() => {
              window.location.reload();
            });
          } else {
            this.$swal("失敗", err.response.data.message, "error");
          }
        }
      });
    },
    async updateProductInfo(product) {
      this.isFetchProductInfo = true
      this.showProductInfo = false
      const { data } = await groupbuyApi.syncStoreGroupBuyProducts({
        sku_serial_number: product.skus[0].sku_serial_number,
        branch_id: this.form.branch_id,
      });

      this.productInfo.id = data.id;
      this.productInfo.name = data.name;
      this.productInfo.barcode = data.poya_prod;
      this.productInfo.ian = data.prod;
      this.productInfo.orig_price = data.orig_price;
      this.productInfo.discount = data.combi_type;

      if (data.mark == 'Y' && parseInt(data.combi_qty) > 1) {
        if (data.combi_type == '' || data.combi_type == null) {
          this.canClick = false;
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "商品價格資訊異常，請掃描其他商品",
          });
        } else {
          this.productInfo.sale_price = data.combi_type;
        }
      } else {
        this.productInfo.sale_price = data.sale_price;
      }
      this.isFetchProductInfo = false
      this.showProductInfo = true
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control,
::v-deep .custom-control-label {
  width: 100%;
}

.buttons__action {
  margin-bottom: 150px;
}

@media screen and (max-width: 768px) {
  .buttons__action > .btn {
    flex: 1;
    line-height: 1.2;
  }
  .invalid-wording-right {
    width: 320%;
    text-align: end;
    float:right
  }
}
</style>
